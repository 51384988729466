var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading,"server-items-length":_vm.total_orders,"items-per-page":_vm.items_per_page,"page":_vm.page,"item-class":_vm.item_background},on:{"pagination":_vm.update_page},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col-md-4"},[_c('router-link',{staticClass:"btn btn-success font-weight-bold btn-sm",attrs:{"to":'/integrate/test-data-date'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-range")]),_vm._v(" Chọn ngày ")],1)],1),_c('div',{staticClass:"col-md-4"},[_c('v-select',{attrs:{"items":_vm.query_status_data,"label":"Trạng thái","item-text":"Name","item-value":"Id"},model:{value:(_vm.query_status),callback:function ($$v) {_vm.query_status=$$v},expression:"query_status"}})],1)])]},proxy:true},{key:"item.Item_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Item_id ? item.Item_id.Name : (item.Product_code ? ("Sản phẩm livestream: " + (item.Product_code)) : ""))+" ")]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.status_to_text(item.Status))+" ")]}},{key:"item.User_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.User_id ? (item.User_id.Name ? item.User_id.Name : item.User_id.User_name) : "")+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Amount.toLocaleString("vi-VN", {style: "currency", currency: "VND"}))+" ")]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }