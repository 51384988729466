<style>
    .item-deleted {
        background-color: #ffaeae;
    }
    .item-cancelled {
        background-color: #ffaeae;
    }
</style>

<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items="orders"
                        :loading="loading"
                        class="elevation-1"

                        @pagination="update_page"
                        :server-items-length="total_orders"
                        :items-per-page="items_per_page"
                        :page="page"
                        :item-class="item_background"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <!-- <router-link :to="'/orders/create'" class="btn btn-success font-weight-bold btn-sm mr-2">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link> -->
                                        <router-link :to="'/integrate/test-data-date'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-calendar-range</v-icon> Chọn ngày
                                        </router-link>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <v-text-field
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        v-model="query"
                                        label="Search"
                                        class="mx-4"
                                        ></v-text-field> -->
                                        <v-select
                                            v-model="query_status"
                                            :items="query_status_data"
                                            label="Trạng thái"
                                            item-text="Name"
                                            item-value="Id"
                                        ></v-select>
                                    </div>
                                </div>
                            </template>

                            
                            <template v-slot:item.Item_id="{ item }">
                                {{ item.Item_id ? item.Item_id.Name : (item.Product_code ? `Sản phẩm livestream: ${item.Product_code}` : "") }}
                            </template>
                            <template v-slot:item.Status="{ item }">
                                {{ status_to_text(item.Status) }}
                            </template>
                            <template v-slot:item.User_id="{ item }">
                                {{ item.User_id ? (item.User_id.Name ? item.User_id.Name : item.User_id.User_name) : "" }}
                            </template>
                            <template v-slot:item.Amount="{ item }">
                                {{ item.Amount.toLocaleString("vi-VN", {style: "currency", currency: "VND"}) }}
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Đơn hàng'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: this.$route.query.q || "",
            status: this.$route.status || 0,
            query_status_data: [
                { Id: -1, Name: "Tất cả" },
                { Id: 0, Name: "Chờ xác nhận" },
                { Id: 1, Name: "Đã chốt đơn" },
                { Id: 5, Name: "Đã huỷ" }
            ],
            query_status: -1,
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Sản phẩm", value: 'Item_id', sortable: false },
                { text: "Số lượng", value: 'Quantity', sortable: false },
                { text: "Tổng tiền", value: 'Amount', sortable: false },
                { text: "Người mua", value: 'User_id', sortable: false },
                { text: "Địa chỉ", value: 'Reg_adr', sortable: false },
                { text: "SĐT", value: 'Reg_phone', sortable: false },
                { text: "Trạng thái", value: 'Status', sortable: false },
            ],

            
        };
    },
    watch: {
        options: {
            handler () {
                this.get_orders();
            },
            deep: true,
        },
        query: {
            handler() {
                this.search_orders();
            },
            deep: true
        },
        query_status: {
            handler() {
                console.log("query_status:", this.query_status)
                this.get_orders();
            },
            deep: true
        }
    },
    computed: {
        orders() {
            return this.$store.getters['integrate/get_orders'];
        },
        total_orders() {
            return this.$store.getters['integrate/get_total_orders'];
        },
        page() {
            return this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        },
        items_per_page() {
            return this.$route.query.limit ? parseInt(this.$route.query.limit) : 15;
        },
        path_name() {
            if(location.href.indexOf("documentation") > -1) {
                return "documentation-test-data-index";
            } else {
                return "integrate-test-data-index";
            }
        }
    },
    components: {
    },
    methods: {
        get_orders() {
            var self    = this;
            self.loading = true;
            const page  = this.$route.query.page || 1;
            const limit = this.$route.query.limit || 15;
            const q = this.query || "";
            const status = this.query_status;
            const start  = this.$route.query.start || "";
            const end = this.$route.query.end || "";

            var payload = {
                pageSize: limit,
                pageIndex: page,
            };

            if(q) {
                payload.nameQuery = q;
            }
            // if(status) {
                payload.status = status;
            // }
            if(start && end) {
                payload.start = start;
                payload.end = end;
            }

            this.$router.push({
                name: this.path_name,
                query: { page, limit, q, status, start, end }
            }).catch(() => { });

            this.$store.dispatch('integrate/get_all_orders', payload).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        search_orders() {
            const page  = 1;
            const limit = this.$route.query.limit || 15;
            const status = this.query_status;
            const start  = this.$route.query.start || "";
            const end = this.$route.query.end || "";


            this.$router.push({
                name: this.path_name,
                query: { page, limit, q: this.query, status, start, end }
            }).catch(() => { });
            this.get_orders();
        },
        item_background: function (item) {
            return item.Status == 5 ? 'item-cancelled' : ''
        },
        status_to_text(status) {
            if(status == 0) return "Chờ xác nhận";
            if(status == 1) return "Đã chốt đơn";
            if(status == 5) return "Đã huỷ";
            return "Không xác định";
        },
        update_page(pagination) {
            const page  = pagination.page;
            const limit = pagination.itemsPerPage;
            const q = this.query;
            const status = this.query_status;
            const start  = this.$route.query.start || "";
            const end = this.$route.query.end || "";

            this.$router.push({
                name: this.path_name,
                query: { page, limit, q, status, start, end }
            }).catch(() => { });
            this.get_orders();
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Đơn hàng", route: "index" }, { title: "Tất cả" } ]);
        this.get_orders();
    }
};
</script>

